import { Group, Progress, Title } from "@mantine/core";

interface IProgressBar {
  value?: number;
}

export function ProgressBar({ value = 0, ...props }: IProgressBar) {
  return (
    <Group position="apart" {...props}>
      <Title inherit size={12} weight="bold" order={4}>
        Download Progress
      </Title>
      <Progress
        size={6}
        className="flex-1 flex-shrink-0"
        value={value}
        animate
      />
    </Group>
  );
}
