import { useCallback, useMemo } from "react";
import { useQuery } from "react-query";

import { authGetter } from "@/utils/utils";
import { PermissionListDataType, PermissionListType } from "@/sample/queries";
import { decrypt } from "@/utils/lib";

import { showErrorNotification } from "./showErrorNotification";
import { queryVars } from "./useIncrementalQuery";
import { useLogout } from "./useLogout";

type Modules =
  | "ACCOUNT"
  | "ACCOUNTING_POSTING"
  | "BOARD"
  | "BOARD_TRANSACTION_FEE"
  | "CASH_ADVANCE"
  | "CLOSING_PRICE_METHODOLOGY"
  | "COMMODITY"
  | "CONTRACT_NOTE"
  | "CURRENCY"
  | "DERIVATIVE_CONTRACT"
  | "DERIVATIVE_CONTRACT_SETTLEMENT"
  | "DISPATCH"
  | "DISPATCH_NAME"
  | "ERROR_LOG"
  | "EVENT"
  | "LISTING"
  | "LOCATION_DIFFERENTIALS"
  | "INITIAL_DISPATCH"
  | "INVOICE"
  | "MANUAL_POSTINGS_HISTORY"
  | "MARGIN_ACCOUNT"
  | "RULES"
  | "ORDER_BOOKS"
  | "ORDER_TYPE"
  | "OTC_DISPATCH"
  | "OTC_GRN"
  | "OTC_TRADES"
  | "PERMISSION"
  | "SECURITIES"
  | "SECURITY"
  | "SECURITY_PRICE"
  | "SECURITY_TRANSACTION_FEE"
  | "STOCK_AND_CASH_COVER"
  | "STOCK_COVER"
  | "TRANSACTION_BANKS"
  | "WAREHOUSE"
  | "WITHDRAWAL_REQUEST"
  | "LOGISTICS_DIFFERENTIAL";

type Person =
  | "CLIENT"
  | "GROUP"
  | "LOGISTICS_OFFICER"
  | "OMS_PROVIDER"
  | "TRADED_CLIENT"
  | "USER";

type Property =
  | ""
  | "BANK_INFO"
  | "CASH_COVER"
  | "CSD"
  | "REPORT"
  | "PORTFOLIO"
  | "SECURITY_POSITION"
  | "SETTINGS"
  | "WALLET_HISTORY"
  | "WALLET_STATEMENT";

type Orphan =
  | ""
  | "DOCUMENT_TYPE"
  | "ITEM"
  | "PARTY_CATEGORY"
  | "PRODUCT_TYPE"
  | "USER";

type Action =
  | "ADD"
  | "AUDIT"
  | "APPROVE"
  | "ACTIVATE"
  | "CANCEL"
  | "CREATE"
  | "CONVERT"
  | "CROSS_TRADE"
  | "DEACTIVATE"
  | "DELETE"
  | "DELIST"
  | "DOWNLOAD"
  | "EDIT"
  | "FUND"
  | "GIVE"
  | "LIEN"
  | "LIQUIDATE"
  | "LOCK"
  | "LOG"
  | "MARK"
  | "MAKE"
  | "MANAGE"
  | "RESEND"
  | "REVERT"
  | "SEND"
  | "SET"
  | "SUSPEND"
  | "TRANSFER"
  | "TRIGGER"
  | "UPDATE"
  | "VALIDATE"
  | "VIEW";

type Delimiter<D> = D extends "" ? "" : D extends string ? `_${D}` : never;

type LegacyPermissions = "LOGISTICS_OFFICER";
type ModulePermissions = `CAN${Delimiter<Action>}${Delimiter<Modules>}`;
type PropertyPermissions =
  `CAN${Delimiter<Action>}${Delimiter<Modules>}${Delimiter<Property>}`;
type PersonPermissions =
  `CAN${Delimiter<Action>}${Delimiter<Person>}${Delimiter<Property>}`;
type OrphanPermissions = `CAN${Delimiter<Action>}${Delimiter<Orphan>}`;

export type PermissionType =
  | ModulePermissions
  | PropertyPermissions
  | PersonPermissions
  | OrphanPermissions
  | LegacyPermissions;

export function usePermissions(value: keyof PermissionListDataType = "id") {
  const logout = useLogout();
  const { keys, query } = queryVars;
  const {
    data: permissionsData,
    isLoading: permissionLoading,
    isError: permissionsError,
  } = useQuery<PermissionListType>(keys.permissions, () => {
    return authGetter(query.permissions);
  });

  const permissionOptions = useMemo(() => {
    return (
      permissionsData?.data?.map((option) => ({
        label: option.name,
        value: `${option[value]}`,
      })) ?? []
    );
  }, [permissionLoading]);

  if (permissionsError) showErrorNotification("loading permissions");

  const checkPermission = useCallback<(permission: PermissionType) => boolean>(
    (permission: PermissionType) => {
      try {
        const data = localStorage.getItem("ecn.user.permissions");
        if (data) {
          const permissions = JSON.parse(decrypt(data));
          return permissions.includes(permission.toLocaleLowerCase());
        }
        return false;
      } catch (error) {
        // TODO: remove this when the issue is fixed
        if (process.env.NODE_ENV === "development") console.error(error);

        // logout();
        return false;
      }
    },
    [permissionLoading]
  );
  return {
    checkPermission,
    permissionOptions,
  };
}


