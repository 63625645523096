import { Divider, GroupProps, Text } from "@mantine/core";

import { LastExportIdType } from "@/sample/queries";
import { ProgressBar } from "./progressBar";

interface IProgressFlow extends GroupProps {
  progress?: LastExportIdType["detail"];
}

export function ProgressFlow({ progress, ...props }: IProgressFlow) {
  if (!progress) return null;

  return (
    <>
      <Divider className="bg-gray-300" orientation="horizontal" />

      {typeof progress === "object" ? (
        progress?.percentage_done === "IN PROGRESS" ? (
          <Text size="sm" weight="bold" ml="auto">
            IN PROGRESS
          </Text>
        ) : (
          <ProgressBar
            {...props}
            value={parseFloat(progress?.percentage_done?.slice(0, -1) ?? "0")}
          />
        )
      ) : (
        <ProgressBar {...props} />
      )}
    </>
  );
}
